<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <!-- Avatar Row -->

    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row">
            <div slot="activator">
              <v-avatar
                size="150px"
                v-ripple
                v-if="!avatar"
                class="grey lighten-3 mb-3"
              >
                <span>Click to add avatar</span>
              </v-avatar>
              <v-avatar size="150px" v-ripple class="mb-3 mr-2">
                <img :src="avatar.imageURL" alt />
              </v-avatar>
            </div>
            <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
            <div>
              <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
                {{ data_local.Email }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <v-text-field
            class="w-full mt-4"
            :label="this.$t('_user_management.user_edit.User_Name')"
            v-model="data_local.UserName"
            v-validate="'required'"
            name="UserName"
            solo
            :rules="validations.userNameRules"
          />

          <v-text-field
            solo
            class="w-full mt-4"
            :label="this.$t('_user_management.user_edit.Name')"
            v-model="data_local.FirstName"
            v-validate="'required'"
            name="FirstName"
            :rules="validations.nameRules"
          />

          <v-text-field
            solo
            class="w-full mt-4"
            :label="this.$t('_user_management.user_edit.Last_Name')"
            v-model="data_local.LastName"
            v-validate="'required'"
            name="LastName"
            :rules="validations.nameRules"
          />
          <v-select
            class="w-full mt-4"
            v-model="data_local.ProvinceId"
            :items="provinceList"
            item-text="label"
            item-value="value"
            :label="this.$t('_user_management.Province')"
            :rules="validations.provinceRule"
            solo
          ></v-select>
          <v-select
            class="w-full mt-4"
            v-model="data_local.DistrictId"
            :items="districtList"
            item-text="label"
            item-value="value"
            name="District"
            :label="this.$t('_user_management.District')"
            solo
          ></v-select>
          <v-text-field
            class="w-full mt-4"
            v-model="data_local.Level"
            :label="this.$t('_user_management.Level')"
            solo
          ></v-text-field>
          <v-text-field
            class="w-full mt-4"
            v-model="data_local.Unit"
            :label="this.$t('_user_management.Unit')"
            solo
          ></v-text-field>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <v-text-field
            solo
            class="w-full mt-4"
            :label="this.$t('_user_management.user_edit.Email')"
            v-model="data_local.Email"
            type="Email"
            v-validate="'required|email'"
            name="Email"
            :rules="validations.emailRules"
          />

          <v-text-field
            solo
            class="w-full mt-4"
            v-model="data_local.Identifier"
            :label="this.$t('_user_management.Identifier')"
            :rules="validations.tcNoRules"
          />

          <v-text-field
            solo
            class="w-full mt-4"
            v-model="data_local.MobilePhoneNumber"
            :label="this.$t('_user_management.Mobile')"
          />

          <div class="mt-4">
            <span
              class="text-danger text-sm"
              v-show="errors.has('FirstName')"
              >{{ errors.first("FirstName") }}</span
            >

            <v-select
              v-model="userForm.UserRoles"
              :items="userRolesList"
              item-text="text"
              item-value="value"
              label="User Role"
              persistent-hint
              return-object
              single-line
              solo
              :rules="validations.userRolesRules"
            ></v-select>
          </div>

          <div class="mt-4">
            <v-select
              v-model="userForm.Status"
              :items="statusList"
              item-text="text"
              item-value="value"
              label="User Status"
              persistent-hint
              return-object
              single-line
              solo
              :rules="validations.userStatusRule"
            ></v-select>
            <v-text-field
              class="w-full mt-4"
              v-model="data_local.Title"
              :label="this.$t('_user_management.Title')"
              solo
            ></v-text-field>

            <span class="text-danger text-sm" v-show="errors.has('Status')">{{
              errors.first("Status")
            }}</span>
          </div>
        </div>
      </div>
    </v-form>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button
            class="ml-auto mt-2"
            color="danger"
            @click="goToUserList()"
            :disabled="!validateForm"
            >{{ $t("_common.Cancel") }}</vs-button
          >
          <vs-button
            class="ml-4 mt-2"
            color="success"
            @click="save"
            :disabled="!validateForm"
            >{{ $t("_common.Save_Changes") }}</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from "../../../../components/extra-components/avatar-upload/ImageInput";

export default {
  components: {
    ImageInput,
  },
  props: {
    formData: Object,
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      districtList: [],
      provinceList: [],
      validations: {
        userRolesRules: this.$goc.validate.create().required(),
        emailRules: this.$goc.validate.create().required().email(),
        userNameRules: this.$goc.validate.create().required().min(3).max(20),
        nameRules: this.$goc.validate.create().required().min(2).max(50),
        userStatusRule: this.$goc.validate.create().required(),
        provinceRule: this.$goc.validate.create().required(),
        tcNoRules: this.$goc.validate.create().min(11).max(11),
      },
      valid: true,
      avatar: {
        imageURL: "",
      },
      userRolesList: [],
      statusList: [],
      UserRoles: "",
      Status: "",
      userForm: {
        Status: "",
        UserRoles: "",
      },

      data_local: JSON.parse(JSON.stringify(this.data)),

      statusOptions: [
        { label: "Blocked", value: "blocked" },
        { label: "Deactivated", value: "deactivated" },
        { label: "Active", value: "active" },
      ],
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
      ],
      roleTexts: {
        LmsAdministrator: this.$t(
          "_user_management._popup_Add_User._popup_Role.Administrator"
        ),
        LmsTeacher: this.$t(
          "_user_management._popup_Add_User._popup_Role.Teacher"
        ),
        LmsUser: this.$t("_user_management._popup_Add_User._popup_Role.User"),
      },
    };
  },
  mounted() {
    this.getProvinceList();
    let _self = this;
    this.$goc.setModule("UserEditTab", this);
    this.avatar.imageURL =
      this.data_local.Avatar ||
      require("@/assets/images/portrait/small/avatar.png");

    this.$goc.request.common.getRoles((roleList) => {
      _self.userRolesList = roleList;
      _self.userForm.UserRoles = roleList.find(function (element) {
        return element.text == _self.roleTexts[_self.data_local.UserRoles];
      });
    });

    this.$goc.request.common.getUserStatus((statusList) => {
      _self.statusList = statusList;
      _self.userForm.Status = statusList.find(function (element) {
        return element.value == _self.data_local.Status;
      });
    });
    this.$goc.setModule("UserEditTabAccount", this);
  },
  watch: {
    "data_local.ProvinceId": {
      immediate: true,
      handler() {
        if (this.data_local.ProvinceId) {
          let query = `${this.data_local.ProvinceId}`;
          this.$goc.request.get(
            this.$enums.API.GetDisricts + query,
            (result) => {
              if (result.IsSuccess === true) {
                this.districtList = result.Result.Districts.map((d) => ({
                  label: d.DistrictName,
                  value: d.Id,
                }));
              }
            }
          );
        } else {
          this.districtList = [];
          this.data_local.DistrictId = "";
        }
      },
    },
  },
  computed: {
    status_local: {
      get() {
        return {
          label: this.capitalize(this.data_local.status),
          value: this.data_local.status,
        };
      },
      set(obj) {
        this.data_local.status = obj.value;
      },
    },
    role_local: {
      get() {
        return {
          label: this.capitalize(this.data_local.UserRoles),
          value: this.data_local.UserRoles,
        };
      },
      set(obj) {
        this.data_local.role = obj.value;
      },
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    StatusChange(data) {
      this.$goc.console.log(data);
    },
    resetAvatar() {
      this.data_local.Avatar = null;
      this.avatar = {
        imageURL: require("@/assets/images/portrait/small/avatar.png"),
      };
    },
    goToUserList() {
      this.$goc.page.load("/user/management");
    },
    capitalize(str) {
      if (str) {
        return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
      }
      return "";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.data_local.Status = this.userForm.Status.value;
        this.data_local.UserRoleId = this.userForm.UserRoles.value;
        if (this.avatar.formData) {
          this.$goc.request.postMultipart(
            this.$enums.API.Images.Single,
            this.avatar.formData,
            (data) => {
              let avatarUrl = data.result.url;
              if (avatarUrl) {
                this.data_local.Avatar = avatarUrl;
              } else {
                this.data_local.Avatar = require("@/assets/images/portrait/small/avatar.png");
              }
              this.updateProfileCall(this.data_local);
            }
          );
        } else {
          this.updateProfileCall(this.data_local);
        }
      }
    },
    updateProfileCall(userDto) {
      this.$goc.console.log(userDto);
      this.$goc.request.post(this.$enums.API.Update_Profile, userDto, () => {
        this.$goc.notify.success({
          title: this.$t("_common.Success"),
          message: this.$t("_user_profile.Account.SuccessMessage"),
        });
        this.$router.push("/user/management").catch(() => {});
      });
    },
    reset_data() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
      this.avatar.imageURL = this.data_local.Avatar;
    },
    validate() {
      return !!this.$refs.form.validate();
    },
    getProvinceList() {
      this.$goc.request.get(this.$enums.API.GetProvinces, (result) => {
        if (result.IsSuccess === true) {
          this.provinceList = result.Result.Provinces.map((d) => ({
            label: d.ProvinceName,
            value: d.Id,
          }));
        }
      });
    },
  },
};
</script>
